import { useEffect, useState } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import ForgotPassword from "./Components/Authentication/ForgotPassword";
import SignIn from "./Components/Authentication/SignIn";
import SignUp from "./Components/Authentication/SignUp";
import Home from "./Components/HomePage/Home";
import NotFound from "./Components/NotFound/NotFound";
import AddToCart from "./Components/Store/AddToCart/AddtoCart";
import Categories from "./Components/Store/Categories/Categories";

import ManageMembers from "./Components/Store/MangeMembers/ManageMembers";
import Products from "./Components/Store/Products/Products";
import Wishlist from "./Components/Store/Wishlist/Wishlist";
import AuthLayout from "./Layout/AuthLayout/AuthLayout";
import HomeLayout from "./Layout/HomeLayout/HomeLayout";
import StoreLayout from "./Layout/StoreLayout/StoreLayout";
import { AuthContext } from "./Services/Context";
import {
  AuthPrivateRoute,
  CustomerPrivateRoute,
  StorePrivateRoute,
  VerifyPrivateRoute,
} from "./Utility/PrivateRoute";
import { getCookie, setCookie } from "./Utility/Utility";
import ResetPassword from "./Components/Authentication/ResetPassword";
import Checkout from "./Components/Store/AddToCart/CheckOut";
import PaymentRequest from "./Components/Store/PaymentRequest/PaymentRequest";
import InventoryList from "./Components/Store/Products/InventoryList";
import BillList from "./Components/Store/BillList/BillList";
import ChangePassword from "./Components/Store/Profile/ChangePassword";
import ViewProfile from "./Components/Store/Profile/ViewProfile";
import CartList from "./Components/Store/AddToCart/CartList";
import Invoice from "./Components/Store/Invoice/Invoice";
import TrackOrder from "./Components/Store/DashBoard/TrackOrder";
import BalanceHistory from "./Components/Store/DashBoard/BalanceHistory";
import Store from "./Components/Store/DashBoard/Store";
import AllProducts from "./Components/Store/AllProducts/AllProducts";
import CustomerLayout from "./Layout/CustomerLayout/CustomerLayout";
import CustomerCartList from "./Components/Store/AllProducts/CustomerCartList";
import Success from "./Components/Store/Invoice/Success";
import CustomerInvoice from "./Components/Store/CustomerInvoice/CustomerInvoice";
import VerifyOTP from "./Components/Authentication/VerifyOTP";
import Myorder from "./Components/Store/Myorder/Myorder";
import MyorderView from "./Components/Store/Myorder/MyorderView";
import ComplaintList from "./Components/Store/ComplaintList/ComplaintList";
import PaymentMode from "./Components/Store/PaymentMode/PaymentMode";
import OtpVerification from "./Components/Authentication/OtpVerification";
import BrochureUser from "./Components/Store/BrochureUser";
import InventoryHistory from "./Components/Store/Products/InventoryHistory";
import KansasOrder from "./Components/Store/KansasOrder/KansasOrder";
import KansasOrderView from "./Components/Store/KansasOrder/KansasOrderView";
function App() {
  let location = useLocation();
  const [AuthToken, setAuthToken] = useState(
    getCookie("userlog") !== "" ? JSON.parse(getCookie("userlog")) : null
  );

  const handleAuthLogin = (data) => {
    setAuthToken(data);
  };

  const handleLogout = () => {
    setCookie("userlog", null, null);
    sessionStorage.removeItem("otpStatus");
    setCookie("resetKey", null, null);
    handleAuthLogin(null);
  };

  useEffect(() => {
    document.getElementById("root").scrollIntoView();
  }, [location]);

  useEffect(() => {
    let timeoutId;

    const resetTimer = () => {
      clearTimeout(timeoutId);
      if (AuthToken) {
        timeoutId = setTimeout(handleLogout, 900000);
      }
    };

    const handleKeyDown = () => resetTimer();
    const handleMouseDown = () => resetTimer();
    const handleMouseMove = () => resetTimer();
    const handleTouchStart = () => resetTimer();

    window.addEventListener("keydown", handleKeyDown);
    window.addEventListener("mousedown", handleMouseDown);
    window.addEventListener("mousemove", handleMouseMove);
    window.addEventListener("touchstart", handleTouchStart);

    return () => {
      clearTimeout(timeoutId);
      window.removeEventListener("keydown", handleKeyDown);
      window.removeEventListener("mousedown", handleMouseDown);
      window.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("touchstart", handleTouchStart);
    };
  }, [handleLogout, AuthToken]);
  useEffect(() => {
    handleRemoveBillData();
  }, [location.pathname]);

  const handleRemoveBillData = () => {
    if (
      ![
        "/store/checkout",
        "/store/invoice",
        "/store/categories",
        "/store/cartlist",
      ].includes(location.pathname)
    ) {
      sessionStorage.removeItem("billProducts");
      sessionStorage.removeItem("forCustomerDetails");
      sessionStorage.removeItem("storeDetails");
    }
    if (location.pathname.includes("/store")) {
      sessionStorage.removeItem("customerlog");
      sessionStorage.removeItem("custmerDetails");
    }
  };

  return (
    <AuthContext.Provider
      value={{
        user: AuthToken,
        handleAuthLogin: handleAuthLogin,
      }}
    >
      <Routes>
        <Route
          path="/auth"
          element={
            <AuthPrivateRoute>
              <AuthLayout />
            </AuthPrivateRoute>
          }
        >
          <Route index element={<SignIn />} />

          <Route path="forgotpassword" element={<ForgotPassword />} />
          <Route path="otpverification" element={<OtpVerification />} />
          <Route path="resetpassword" element={<ResetPassword />} />
        </Route>
        <Route path="/" element={<HomeLayout />}>
          <Route index element={<Home />} />
        </Route>

        <Route path="kansas_product" element={<CustomerLayout />}>
          <Route path="shop/:id" element={<SignUp />} />
          <Route
            path="allproducts"
            element={
              <CustomerPrivateRoute>
                <AllProducts />
              </CustomerPrivateRoute>
            }
          />
          <Route
            path="customercartlist"
            element={
              <CustomerPrivateRoute>
                <CustomerCartList />
              </CustomerPrivateRoute>
            }
          />
          <Route
            path="checkout"
            element={
              <CustomerPrivateRoute>
                <Checkout />
              </CustomerPrivateRoute>
            }
          />
          <Route
            path="invoice"
            element={
              <CustomerPrivateRoute>
                <Invoice />
              </CustomerPrivateRoute>
            }
          />
          <Route
            path="success"
            element={
              <CustomerPrivateRoute>
                <Success />
              </CustomerPrivateRoute>
            }
          />
        </Route>

        <Route
          path="/store"
          element={
            <StorePrivateRoute>
              <StoreLayout />
            </StorePrivateRoute>
          }
        >
          <Route index element={<Store />} />
          <Route path="products" element={<Products />} />
          <Route path="addtocart" element={<AddToCart />} />
          <Route path="wishlist" element={<Wishlist />} />
          <Route path="managemembers" element={<ManageMembers />} />
          <Route path="categories" element={<Categories />} />
          <Route path="checkout" element={<Checkout />} />
          <Route path="payment_request" element={<PaymentRequest />} />
          <Route path="inventory_list" element={<InventoryList />} />
          <Route path="Inventory_history" element={<InventoryHistory />} />
          <Route path="bill_list" element={<BillList />} />
          <Route path="balance_history" element={<BalanceHistory />} />
          <Route path="change_password" element={<ChangePassword />} />
          <Route path="view_profile" element={<ViewProfile />} />
          <Route path="track_order" element={<TrackOrder />} />
          <Route path="cartlist" element={<CartList />} />
          <Route path="invoice" element={<Invoice />} />
          <Route path="success" element={<Success />} />
          <Route path="customerinvoice" element={<CustomerInvoice />} />
          <Route path="myorder" element={<Myorder />} />
          <Route path="myorderview" element={<MyorderView />} />
          <Route path="list_complaint" element={<ComplaintList />} />
          <Route path="paymentmode" element={<PaymentMode />} />
          <Route path="brochure_user" element={<BrochureUser />} />
          <Route path="customerOrderrequest" element={<KansasOrder />} />
          <Route
            path="customerOrderrequestview"
            element={<KansasOrderView />}
          />
        </Route>
        <Route
          path="/verifyotp"
          element={
            <VerifyPrivateRoute>
              <VerifyOTP />
            </VerifyPrivateRoute>
          }
        />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </AuthContext.Provider>
  );
}

export default App;
