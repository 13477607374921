import { useEffect, useState } from "react";
import FileSaver from "file-saver";
import ReactPaginate from "react-paginate";
import { Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import toast from "react-hot-toast";
import moment from "moment";
import { DatePicker } from "antd";
import dayjs from "dayjs";
import ViewDetails from "./ViewDetails";
import Styles from "./PaymentRequest.module.css";
import Popup from "./DetailsPopup";
import EditDetails from "./EditDetails";
import editIcon from "../../../Assets/edit_icon.svg";
import deleteIcon from "../../../Assets/delete.svg";
import viewIcon from "../../../Assets/view.svg";
import downloadIcon from "../../../Assets/download_icon.png";
import {
  deletePaymentRequestService,
  listPaymentRequestService,
} from "../../../Services/ApiServices";
import LeftArrow from "../../../Assets/modal_left_arrow.png";
import RightArrow from "../../../Assets/modal_right_arrow.png";
import plusIcon from "../../../Assets/add.png";
import Loader from "../../../SharedComponents/Loader/Loader";
import searchIcon from "../../../Assets/searchFilter.png";

export default function PaymentRequest() {
  const [isShowPopup, setIsShowPopup] = useState(false);
  const [isEditShowPopup, setIsEditShowPopup] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [isViewShowPopup, setIsViewShowPopup] = useState(false);
  const [requestId, setRequestId] = useState(0);
  const [editData, setEditData] = useState({ id: "", data: "" });
  const [page, setPage] = useState(0);
  const [isConfirmation, setIsConfirmation] = useState({
    status: false,
    id: "",
  });
  const [showFilter, setShowFilter] = useState(false);
  const [filterData, setFilterData] = useState({
    payeeName: "",
    fromDate: "",
    toDate: "",
    searchKey: "",
    approveStatus: "",
  });
  const [isLoader, setIsLoader] = useState(false);

  useEffect(() => {
    handleListPayment();
  }, []);

  const handleListPayment = (pageNumber = 1, csv) => {
    setIsLoader(true);
    let formData = new FormData();
    formData.append("payee_name", filterData.payeeName);

    if (filterData.fromDate) {
      formData.append(
        "from_date",
        dayjs(filterData.fromDate).format("YYYY-MM-DD")
      );
    }
    if (filterData.toDate) {
      formData.append("to_date", dayjs(filterData.toDate).format("YYYY-MM-DD"));
    }
    if (csv !== undefined) {
      formData.append("download", parseInt(csv));
    }
    if (filterData.approveStatus !== "") {
      formData.append("approve_status", parseInt(filterData.approveStatus));
    }

    formData.append("payment_mode", filterData.searchKey);

    listPaymentRequestService(formData, pageNumber, 10)
      .then((res) => {
        if (csv !== undefined) {
          let url = res.data;
          let urlArr = url.split(".");
          let ext = urlArr[urlArr.length - 1];
          let dateTime =
            moment().format("DD-MM-YYYY") + "," + moment().format("hh:mm:ss a");
          let fileName = dateTime + "." + ext;
          FileSaver.saveAs(url, fileName);
        } else {
          setTableData(res.data);
          setPage(pageNumber - 1);
        }
      })
      .catch((err) => {
        if (err?.response?.data?.detail) {
          toast(err.response.data.detail[0].msg, { type: "error" });
        } else {
          toast.error("Data Not Found!!");
        }
      })
      .finally(() => setIsLoader(false));
  };

  const handleTableData = (data) => {
    handleListPayment();
  };

  const handleEditPopup = (Editdata, index) => {
    setEditData({ id: index, data: Editdata });
    setIsEditShowPopup(true);
  };

  const handleDelete = (id) => {
    deletePaymentRequestService(id)
      .then((res) => handleListPayment())
      .catch((err) => {
        if (err?.response?.data?.detail) {
          toast.error(err.response.data.detail[0].msg);
        } else {
          toast.error("Something went wrong!!");
        }
      });
  };

  const displayPaymentMethod = (data) => {
    switch (parseInt(data)) {
      case 1:
        return "IMPS";
      case 2:
        return "NEFT";
      case 3:
        return "RTGS";
      case 4:
        return "ONLINE";
      case 5:
        return "UPI";
      case 6:
        return "K_UPI";
      case 7:
        return "QRCODE";
    }
  };

  const handleReset = (pageNumber = 1, csv) => {
    setIsLoader(true);
    setFilterData({
      payeeName: "",
      fromDate: "",
      toDate: "",
      searchKey: "",
      approveStatus: "",
    });
    listPaymentRequestService("", pageNumber, 10)
      .then((res) => {
        if (csv !== undefined) {
          let url = res.data;
          let urlArr = url.split(".");
          let ext = urlArr[urlArr.length - 1];
          let dateTime =
            moment().format("DD-MM-YYYY") + "," + moment().format("hh:mm:ss a");
          let fileName = dateTime + "." + ext;
          FileSaver.saveAs(url, fileName);
        } else {
          setTableData(res.data);
          setPage(pageNumber - 1);
        }
      })
      .catch((err) => {
        if (err?.response?.data?.detail) {
          toast(err.response.data.detail[0].msg, { type: "error" });
        } else {
          toast.error("Data Not Found!!");
        }
      })
      .finally(() => setIsLoader(false));
  };

  return (
    <div className={Styles.parentContainer + " container-fluid"}>
      <Loader isLoader={isLoader} />
      {isShowPopup ? (
        <Popup
          isOpen={isShowPopup}
          handleTableData={handleTableData}
          onHide={() => setIsShowPopup(false)}
        />
      ) : null}

      {isEditShowPopup ? (
        <EditDetails
          isOpen={isEditShowPopup}
          openLoader={isLoader}
          handleTableData={handleTableData}
          data={editData}
          handleListPayment={handleListPayment}
          tableData={tableData}
          onHide={() => setIsEditShowPopup(false)}
        />
      ) : null}

      {isViewShowPopup ? (
        <ViewDetails
          isOpen={isViewShowPopup}
          onHide={() => setIsViewShowPopup(false)}
          id={requestId}
        />
      ) : null}

      <div className={Styles.childContainerParent}>
        <div className={Styles.childContainer}>
          <div className={Styles.imgParentContainer}>
            <p className={Styles.Title + " fontBold"}>
              Payment <span className="orange">Request</span>{" "}
            </p>
            <div className={Styles.csvParentContainer}>
              <img
                className={Styles.searchIcon}
                src={searchIcon}
                alt="search"
                onClick={() => setShowFilter(!showFilter)}
              />
              <button
                className={Styles.CsvBtn + " kstoreFont"}
                onClick={() => handleListPayment(1, 1)}
              >
                Export to Csv
                <img
                  className={Styles.download}
                  src={downloadIcon}
                  alt="download"
                />
              </button>
              <div
                className={Styles.imgContainer}
                onClick={() => setIsShowPopup(true)}
              >
                <span className={Styles.addText + " fontBold"}>
                  <img className={Styles.addIcon} src={plusIcon} alt="plus" />{" "}
                  ADD
                </span>
              </div>
            </div>
          </div>

          {showFilter ? (
            <div className={Styles.filterParentContainer + " row"}>
              <div className="col-md-4 col-sm-12">
                <label className="label">Payee Name</label>
                <input
                  type="text"
                  placeholder="Payee Name"
                  className={Styles.filterInput + " contentFont"}
                  value={filterData.payeeName}
                  onChange={(e) =>
                    setFilterData({ ...filterData, payeeName: e.target.value })
                  }
                />
              </div>

              <div className="col-md-4 col-sm-12">
                <label className="label">Payment Mode</label>
                <select
                  className={Styles.select + " contentFont"}
                  value={filterData.searchKey}
                  id="paymentMode"
                  onChange={(e) =>
                    setFilterData({ ...filterData, searchKey: e.target.value })
                  }
                >
                  <option value="">All</option>
                  <option value="1">IMPS</option>
                  <option value="2">NEFT</option>
                </select>
              </div>
              <div className="col-md-4 col-sm-12">
                <label className="label">Approve Status</label>
                <select
                  className={Styles.select + " contentFont"}
                  value={filterData.approveStatus}
                  id="status"
                  onChange={(e) =>
                    setFilterData({
                      ...filterData,
                      approveStatus: e.target.value,
                    })
                  }
                >
                  <option value="">Select One</option>
                  <option value="0">Waiting</option>
                  <option value="1">Apporved</option>
                  <option value="2">Rejected</option>
                </select>
              </div>
              <div className="col-md-4 col-sm-12">
                <label className="label">From Date</label>
                <DatePicker
                  value={filterData.fromDate}
                  className={Styles.filterInput + " contentFont"}
                  format={"DD/MM/YYYY"}
                  onChange={(val) => {
                    setFilterData({
                      ...filterData,
                      fromDate: val,
                    });
                    if (val > filterData.toDate) {
                      setFilterData((pre) => ({ ...pre, toDate: "" }));
                    }
                  }}
                />
              </div>
              <div className="col-md-4 col-sm-12">
                <label className="label">To Date</label>
                <DatePicker
                  value={filterData.toDate}
                  className={Styles.filterInput + " contentFont"}
                  format={"DD/MM/YYYY"}
                  disabledDate={(current) => {
                    return current < filterData.fromDate;
                  }}
                  onChange={(val) => {
                    setFilterData({
                      ...filterData,
                      toDate: val,
                    });
                  }}
                />
              </div>
              <div className=" d-flex justify-content-end align-self-end col-md-4 pt-2">
                <button
                  onClick={() => handleReset()}
                  className={Styles.resetBtn + " me-2"}
                >
                  reset
                </button>
                <button
                  onClick={() => handleListPayment()}
                  className={Styles.searchBtn}
                >
                  Search
                </button>
              </div>
            </div>
          ) : null}
          <div className={Styles.tableParentContainer}>
            <div className={Styles.TableChildContainer}>
              <table>
                <thead>
                  <tr>
                    <th
                      className={
                        Styles.tableHead + " text-center ContentFont pt-2 pb-2"
                      }
                    >
                      S.No
                    </th>
                    <th
                      className={Styles.tableHead + " text-start ContentFont"}
                    >
                      Date
                    </th>
                    <th
                      className={Styles.tableHead + " text-start ContentFont"}
                    >
                      Payee Name
                    </th>
                    <th
                      className={Styles.tableHead + " text-center ContentFont"}
                    >
                      Payment Mode
                    </th>

                    <th
                      className={Styles.tableHead + " text-center ContentFont"}
                    >
                      Amount
                    </th>
                    <th
                      className={Styles.tableHead + " text-center ContentFont"}
                    >
                      Bank Reference Id
                    </th>
                    <th
                      className={
                        Styles.approveDate + " text-center ContentFont"
                      }
                    >
                      Approve / Rejected Date
                    </th>
                    <th
                      className={Styles.tableHead + " text-center ContentFont"}
                    >
                      Status
                    </th>
                    <th
                      className={
                        Styles.actionsHead + " text-center ContentFont"
                      }
                    >
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {tableData?.items?.map((e, index) => (
                    <tr key={e?.payment_request_id}>
                      <td className={Styles.td + " contentFont text-center"}>
                        {(tableData?.page - 1) * tableData?.size + (index + 1)}
                      </td>
                      <td className={Styles.td + " contentFont text-start"}>
                        {e?.requested_datetime ? e?.requested_datetime : "-"}
                      </td>
                      <td className={Styles.td + " contentFont text-start"}>
                        {e?.payee_name}
                      </td>

                      <td className={Styles.td + " contentFont text-center"}>
                        {displayPaymentMethod(e.payment_mode)}
                      </td>
                      <td className={Styles.price + " contentFont text-center"}>
                        <p className={Styles.priceSymbol}>₹</p>
                        <p className={Styles.priceAmt}>
                          {e?.amount.toFixed(2)}
                        </p>
                      </td>
                      <td className={Styles.td + " contentFont text-center"}>
                        {e?.bank_reference_id ? e?.bank_reference_id : "-"}
                      </td>
                      <td className={Styles.td + " contentFont text-center"}>
                        {e?.approved_date_or_rejected_date
                          ? e?.approved_date_or_rejected_date
                          : "-"}
                      </td>
                      <td
                        style={{
                          color:
                            e.approve_status === 1
                              ? "green"
                              : e.approve_status === 0
                              ? "#c4bb3f"
                              : e.approve_status === 2
                              ? "red"
                              : null,
                        }}
                        className={Styles.td + " contentFont text-center"}
                      >
                        {e?.approve_status_type}
                      </td>
                      <td>
                        <div
                          style={{
                            justifyContent:
                              e?.approve_status === 1 || e?.approve_status === 2
                                ? "start"
                                : "space-around",
                            paddingLeft:
                              e?.approve_status === 1 || e?.approve_status === 2
                                ? "10px"
                                : "0px",
                          }}
                          className={Styles.actionsIconContainer}
                        >
                          <OverlayTrigger
                            placement="bottom"
                            overlay={<Tooltip>View</Tooltip>}
                          >
                            <div className={Styles.IconChildContainer}>
                              <img
                                className={Styles.actionsIcon}
                                src={viewIcon}
                                alt="view"
                                onClick={() => {
                                  setRequestId(e.payment_request_id);
                                  setIsViewShowPopup(true);
                                }}
                              />
                            </div>
                          </OverlayTrigger>

                          <>
                            <OverlayTrigger
                              placement="bottom"
                              overlay={<Tooltip>Edit</Tooltip>}
                            >
                              <div
                                style={{
                                  display:
                                    e?.approve_status === 1 ||
                                    e?.approve_status === 2
                                      ? "none"
                                      : "flex",
                                }}
                                className={Styles.IconChildContainer}
                              >
                                <img
                                  className={Styles.actionsIcon}
                                  src={editIcon}
                                  onClick={() => handleEditPopup(e, index)}
                                  alt="edit"
                                />
                              </div>
                            </OverlayTrigger>
                            <OverlayTrigger
                              placement="bottom"
                              overlay={<Tooltip>Delete</Tooltip>}
                            >
                              <div
                                style={{
                                  display:
                                    e?.approve_status === 1 ||
                                    e?.approve_status === 2
                                      ? "none"
                                      : "flex",
                                }}
                                className={Styles.IconChildContainer}
                              >
                                <img
                                  onClick={() => {
                                    setIsConfirmation({
                                      ...isConfirmation,
                                      status: true,
                                      id: e.payment_request_id,
                                    });
                                  }}
                                  className={Styles.actionsIcon}
                                  src={deleteIcon}
                                  alt="delete"
                                />
                              </div>
                            </OverlayTrigger>
                          </>
                        </div>
                      </td>
                    </tr>
                  ))}
                  {tableData?.items?.length === 0 ? (
                    <tr>
                      <td
                        colSpan={8}
                        className="fs-6 text-secondary p-3 text-center"
                      >
                        Data Not Found
                      </td>
                    </tr>
                  ) : null}
                </tbody>
              </table>
            </div>
          </div>

          <Modal
            size="md"
            centered
            show={isConfirmation.status}
            onHide={() =>
              setIsConfirmation({ ...isConfirmation, status: false })
            }
          >
            <Modal.Body>
              <div>
                <p className="text-start kstoreFont text-secondary fs-6 fw-bold">
                  Are you sure want to delete?
                </p>
                <div className={Styles.deleteConfirmationBtnContainer}>
                  <div className={Styles.btnParent}>
                    <button
                      className={
                        Styles.deleteConfirmationCancelBtn +
                        " contentFont text-uppercase"
                      }
                      onClick={() =>
                        setIsConfirmation({ ...isConfirmation, status: false })
                      }
                    >
                      Cancel
                    </button>
                    <button
                      onClick={() => {
                        handleDelete(isConfirmation.id);
                        setIsConfirmation({ ...isConfirmation, status: false });
                      }}
                      className={
                        Styles.deleteConfirmationOkBtn +
                        " contentFont text-uppercase"
                      }
                    >
                      Delete
                    </button>
                  </div>
                </div>
              </div>
            </Modal.Body>
          </Modal>

          <div
            style={{ display: tableData.total <= 10 ? "none" : "flex" }}
            className={Styles.paginationContainer}
          >
            <ReactPaginate
              previousLabel={<img src={LeftArrow} alt="left" />}
              nextLabel={<img src={RightArrow} alt="right" />}
              breakLabel="..."
              pageCount={Math.ceil(tableData.total) / 10}
              marginPagesDisplayed={1}
              pageRangeDisplayed={1}
              forcePage={page}
              onPageChange={({ selected }) => handleListPayment(selected + 1)}
              containerClassName={"pagination m-0"}
              pageClassName={"page-item"}
              pageLinkClassName={
                "page-link text-secondary rounded-circle bg-light pt-1 pb-1 ps-2 pe-2 ms-1 me-1 border-0 shadow-none"
              }
              previousClassName={"page-item"}
              previousLinkClassName={
                "page-link border-0 text-dark p-1 rounded-circle bg_orange shadow-none me-1"
              }
              nextClassName={"page-item "}
              nextLinkClassName={
                "page-link border-0 text-dark p-1 bg_orange rounded-circle shadow-none ms-1"
              }
              activeClassName={"active"}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
