import { useFormik } from "formik";
import { useEffect, useState } from "react";
import * as Yup from "yup";
import { useLocation, useNavigate } from "react-router-dom";
import { Modal } from "react-bootstrap";
import toast from "react-hot-toast";
import ReactSelect from "react-select";
import Styles from "./CheckOut.module.css";
import next from "../../../Assets/next.png";
import nextWhite from "../../../Assets/next_white.png";
import cancel from "../../../Assets/cross.png";
import {
  cityService,
  emiProviderService,
  stateService,
  viewProfileService,
} from "../../../Services/ApiServices";
import Header from "../../../SharedComponents/Header/Header";
import Loader from "../../../SharedComponents/Loader/Loader";
import { phoneExp } from "../../../Utility/Utility";

let EMAIL_REGEX = new RegExp(
  /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
);
const PINCODE_REGEX = /^[1-9][0-9]{5}$/;

export default function Checkout() {
  let { state, pathname } = useLocation();
  let navigate = useNavigate();
  let isDisabled = state?.status === 2;
  const [isShowShipping, setIsShowShipping] = useState(false);
  const [isNeedCustomerUrl, setIsNeedCustomerUrl] = useState(false);
  const [states, setStates] = useState([]);
  const [city, setCity] = useState([]);
  const [loader, setLoader] = useState(false);
  const [billOptions, setBillOption] = useState(1);
  const [selectRef, setSelectRef] = useState({ state: "", city: "" });
  const [showModal, setShowModal] = useState(false);
  const [emiProviders, setEmiProviders] = useState([]);
  const [paymentLinkError, setPaymentLinkError] = useState(false);

  useEffect(() => {
    stateService()
      .then((res) => setStates(res.data))
      .catch((err) => console.log(err));
    if (state?.status !== 2) {
      handleUserData();
    }
  }, []);

  useEffect(() => {
    if (state === null) {
      navigate("/store");
    }
    if (state?.status === 2) {
      handleConfirmation();
    }
    handleEmiProviders();
  }, []);

  const handleEmiProviders = () => {
    emiProviderService()
      .then((res) => {
        setEmiProviders(res.data);
      })
      .catch((err) => {
        if (err?.response?.data?.detail) {
          toast(err.response.data.detail[0].msg, { type: "error" });
        } else {
          toast("Something went wrong!!");
        }
      });
  };

  useEffect(() => {
    if (billOptions === 1) {
      handleSetCustomerDetails();
    }
  }, []);

  useEffect(() => {
    if (state.fromCustomerWishlist) {
      handleCustomerWishlistData();
    }
  }, []);

  useEffect(() => {
    let userData = JSON.parse(sessionStorage.getItem("custmerDetails"));
    if (userData === null) {
      handleCustomerData();
    }
  }, []);

  const handleCustomerWishlistData = () => {
    setValues({
      ...values,
      billingName: state?.CustomerDetails?.customer_name,
      billingEmail: state?.CustomerDetails?.email,
      billingAddress: state?.CustomerDetails?.address,
      billingCity: state?.CustomerDetails?.city,
      billingState: state?.CustomerDetails?.state,
      billingMobile: state?.CustomerDetails?.mobile_no,
      billingPincode: state?.CustomerDetails?.pincode,
    });
    handleCity(state?.CustomerDetails?.state);
  };

  const handleCustomerData = () => {
    let customerLoginDetail = JSON.parse(sessionStorage.getItem("customerlog"));
    if (customerLoginDetail !== null) {
      setValues({
        ...values,
        billingName: customerLoginDetail.name,
        billingEmail: customerLoginDetail.email,
        billingMobile: customerLoginDetail.mblNumber,
        billingAddress: customerLoginDetail.address,
        billingCity: "",
        billingState: "",
        billingPincode: "",
        shippingName: "",
        shippingMobile: "",
        shippingState: "",
        shippingCity: "",
        shippingAddress: "",
        shippingEmail: "",
        shippingPincode: "",
      });
    }
  };

  const handleUserData = () => {
    let userData = JSON.parse(sessionStorage.getItem("custmerDetails"));
    let storeData = JSON.parse(sessionStorage.getItem("storeDetails"));
    if (userData !== null && pathname.includes("/kansas_product")) {
      setValues({
        ...values,
        ...userData,
      });
      handleCity(userData.billingState);
    }
    if (
      storeData !== null &&
      state.status !== 2 &&
      !pathname.includes("/kansas_product")
    ) {
      setValues({
        ...values,
        ...storeData,
      });
      handleCity(storeData.billingState);
    }
  };

  const handleSetCustomerDetails = () => {
    let forCustomerDetails = JSON.parse(
      sessionStorage.getItem("forCustomerDetails")
    );
    if (forCustomerDetails !== null && billOptions === 1) {
      setValues({ ...values, ...forCustomerDetails });
      handleCity(forCustomerDetails.billingState);
    }
  };

  const {
    handleChange,
    setValues,
    handleSubmit,
    errors,
    values,
    setFieldValue,
    touched,
    resetForm,
    validateForm,
  } = useFormik({
    initialValues: {
      billingMobile: "",
      billingState: "",
      billingCity: "",
      billingPincode: "",
      billingName: "",
      billingEmail: "",
      billingAddress: "",
      shippingName: "",
      shippingAddress: "",
      shippingMobile: "",
      shippingPincode: "",
      shippingState: "",
      shippingCity: "",
      state_name: "",
      city_name: "",
      shipping_statename: "",
      shipping_cityname: "",
      shippingEmail: "",
      paymentStatus: 3,
      emiOption: "",
      posMachine: "",
      emiProvidername: "",
      paymentLinkNo: "",
      gst_no: "",
      invoice_no: "",
      customer_name: "",
      customer_mobile: "",
    },
    validationSchema: Yup.object({
      billingMobile: Yup.string()
        .min(10, "Mobile number must 10 character")
        .matches(phoneExp, "Mobile number must start with 9 or 8 or 7 or 6 ")
        .required("Required"),
      billingState: Yup.string().required("Required"),
      billingCity: Yup.string().required("Required"),
      billingPincode: Yup.string()
        .matches(PINCODE_REGEX, "Enter Valid Pincode")
        .required("Required"),
      billingName: Yup.string().required("Required"),
      billingAddress: Yup.string().required("Required"),
      billingEmail: Yup.string()
        .matches(EMAIL_REGEX, "Enter Valid email Address")
        .required("Required"),
      gst_no: Yup.string().matches(
        /^[a-zA-Z0-9]{15}$/,
        "Enter Valid GST Number"
      ),
      paymentLinkNo: Yup.string().matches(
        phoneExp,
        "Enter a valid Mobile number"
      ),
      invoice_no:
        state?.status === 1 ? Yup.string().required("Required") : Yup.string(),
      customer_mobile: Yup.string().matches(
        phoneExp,
        "Mobile number must start with 9 or 8 or 7 or 6 "
      ),
    }),
    validate: () => {
      let err = {};
      if (isShowShipping) {
        if (values.shippingName === "") {
          err.shippingName = "Required";
        }
        if (values.shippingAddress === "") {
          err.shippingAddress = "Required";
        }
        if (!phoneExp.test(values.shippingMobile)) {
          err.shippingMobile = "Mobile number must start with 9 or 8 or 7 or 6";
        }
        if (values.shippingMobile === "") {
          err.shippingMobile = "Required";
        }
        if (values.shippingPincode === "") {
          err.shippingPincode = "Required";
        }
        if (
          values.shippingPincode !== "" &&
          !PINCODE_REGEX.test(values.shippingPincode)
        ) {
          err.shippingPincode = "Enter Valid Pincode";
        }
        if (values.shippingState === "") {
          err.shippingState = "Required";
        }
        if (values.shippingCity === "") {
          err.shippingCity = "Required";
        }
        if (values.shippingEmail === "") {
          err.shippingEmail = "Required";
        }
        if (!EMAIL_REGEX.test(values.shippingEmail)) {
          err.shippingEmail = "Enter valid email address";
        }
        if (billOptions === 1 && values.paymentStatus === "") {
          err.paymentStatus = "Required";
        }
      }
      if (isShowShipping || !isShowShipping) {
        if (values.paymentStatus === 4 && values.emiOption === "") {
          err.emiOption = "Required";
        }
        if (values.paymentStatus === 5 && values.posMachine === "") {
          err.posMachine = "Required";
        }
        if (values.paymentStatus === 6 && values.paymentLinkNo === "") {
          err.paymentLinkNo = "Required";
        }
        if (
          values.paymentStatus === 8 &&
          isNeedCustomerUrl &&
          values.customer_name === ""
        ) {
          err.customer_name = "Required";
        }
        if (
          values.paymentStatus === 8 &&
          isNeedCustomerUrl &&
          values.customer_mobile === ""
        ) {
          err.customer_mobile = "Required";
        }
      }

      return err;
    },
    onSubmit: (values) => {
      handleCheckout(values);
      if (billOptions === 1) {
        handleCustomerDetails(values);
      }
      setLoader(true);
    },
  });

  const handleCustomerDetails = (data) => {
    sessionStorage.setItem("forCustomerDetails", JSON.stringify(data));
  };
  const handleCity = (data) => {
    let formData = new FormData();
    formData.append("state_id", parseInt(data));
    cityService(formData)
      .then((res) => setCity(res.data))
      .catch((err) => console.log(err));
  };

  const customStyles = {
    control: () => ({
      // none of react-select's styles are passed to <Control />
      backgroundColor: "white",
      border: "1px solid rgba(0,0,0,0.1)",
      borderRadius: "6px",
      width: "100%",
      padding: "10px 0",
      ":hover": {
        borderColor: "none",
      },
    }),
    indicatorsContainer: () => ({
      display: "none",
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: "black",
      fontSize: "15px",
    }),
    input: (provided, state) => ({
      ...provided,
      color: "black",
      fontSize: "15px",
    }),
    placeholder: (provided, state) => ({
      ...provided,
      fontSize: "15px",
    }),
  };

  const handleCheckout = (data) => {
    let product = state?.items;
    product = product.map((ele) => ({
      ...ele,
      franchiseTotalAmount: parseInt(ele.qty) * parseInt(ele.franchise_price),
    }));
    if (pathname.includes("/kansas_product")) {
      navigate("/kansas_product/invoice", {
        state: {
          productDetails: state,
          billing_details: data,
          shipping_status: isShowShipping,
        },
      });
      sessionStorage.setItem("custmerDetails", JSON.stringify(data));
    } else {
      navigate("/store/invoice", {
        state: {
          billing_details: data,
          wishlist_id: state?.CustomerDetails?.customer_wishlist_id,
          productDetails: { ...state, items: product },
          frombill: billOptions === 2 ? true : false,
          status: state?.status,
          shipping_status: isShowShipping,
          order_from: state?.order_from,
          send_sms: isNeedCustomerUrl,
        },
      });
      sessionStorage.setItem("storeDetails", JSON.stringify(data));
    }
  };

  const handleConfirmation = () => {
    setLoader(true);
    viewProfileService()
      .then(({ data }) => {
        handleCity(data?.state);
        setValues({
          ...values,
          billingName: data.user_name,
          billingAddress: data.address,
          billingMobile: data.mobile_no,
          billingState: data.state,
          billingCity: data.city,
          billingPincode: data.pincode,
          state_name: data.state_name,
          city_name: data.city_name,
          billingEmail: data.email,
        });
      })
      .catch((err) => {
        if (err?.response?.data?.detail) {
          toast(err.response.data.detail[0].msg, { type: "error" });
        } else {
          toast("Something went wrong!!");
        }
      })
      .finally(() => setLoader(false));
  };

  return (
    <div className={Styles.overallParentContainer + " container-fluid"}>
      {pathname.includes("/kansas_product") ? <Header /> : null}
      <Loader isLoader={loader} />
      <div className={Styles.childParent}>
        <div className={Styles.overallChildContainer}>
          <div className={Styles.leftContainer}>
            <form onSubmit={(e) => e.preventDefault()}>
              <div className={Styles.overallLeftContainer}>
                <p className="fontBold">
                  Billing <span className="orange">Details</span>
                </p>

                <div className={Styles.formContainer}>
                  <div className={Styles.inputLeftContainer}>
                    <label className="label">
                      Name <span className="text-danger fs-5">*</span>
                    </label>
                    <div className={Styles.inputContainer}>
                      <input
                        type="text"
                        name="billingName"
                        value={values.billingName}
                        onChange={handleChange}
                        disabled={isDisabled}
                        placeholder="Enter Your Name"
                        className={Styles.input + " kstoreFont"}
                      />
                    </div>
                    {touched.billingName && errors.billingName ? (
                      <div className={Styles.errMsg + " text-danger"}>
                        {errors.billingName}
                      </div>
                    ) : null}
                  </div>

                  <div className={Styles.inputRightContainer}>
                    <label className="label">
                      Mobile Number <span className="text-danger fs-5">*</span>
                    </label>
                    <div className={Styles.inputContainer}>
                      <input
                        type="text"
                        name="billingMobile"
                        value={values.billingMobile}
                        onChange={handleChange}
                        placeholder="Enter Your Mobile Number"
                        maxLength="10"
                        disabled={isDisabled}
                        className={Styles.input + " kstoreFont"}
                        onKeyPress={(e) => {
                          if (!(e.key === "0" || parseInt(e.key))) {
                            e.preventDefault();
                          }
                        }}
                      />
                    </div>
                    {touched.billingMobile && errors.billingMobile ? (
                      <div className={Styles.errMsg + " text-danger"}>
                        {errors.billingMobile}
                      </div>
                    ) : null}
                  </div>
                </div>

                <div className={Styles.formContainer}>
                  <div className={Styles.inputLeftContainer}>
                    <label className="label">
                      State <span className="text-danger fs-5">*</span>
                    </label>
                    <ReactSelect
                      className="basic-single kstoreFont mt-2"
                      classNamePrefix="select"
                      name="billingState"
                      placeholder="Select State..."
                      isClearable={false}
                      isDisabled={isDisabled}
                      value={states.find(
                        (e) => e.state_id === values.billingState
                      )}
                      styles={customStyles}
                      options={states}
                      getOptionLabel={(e) => e.state_name}
                      getOptionValue={(e) => e.state_name}
                      onChange={(e) => {
                        handleCity(e.state_id);
                        setFieldValue("billingState", e.state_id);
                        setFieldValue("state_name", e.state_name);
                        setSelectRef({
                          ...selectRef,
                          state: e.state_name,
                          city: "",
                        });

                        setFieldValue("billingCity", "");
                      }}
                    />
                    {touched.billingState && errors.billingState ? (
                      <div className={Styles.errMsg + " text-danger"}>
                        {errors.billingState}
                      </div>
                    ) : null}
                  </div>
                  <div className={Styles.inputRightContainer}>
                    <label className="label">
                      City <span className="text-danger fs-5">*</span>
                    </label>
                    <ReactSelect
                      className="basic-single kstoreFont mt-2"
                      classNamePrefix="select"
                      placeholder="Select City..."
                      name="billingCity"
                      isDisabled={isDisabled}
                      defaultInputValue={selectRef.city}
                      value={city.find(
                        (e) => e.cities_id === values.billingCity
                      )}
                      isClearable={false}
                      styles={customStyles}
                      options={city}
                      getOptionLabel={(e) => e.cities_name}
                      getOptionValue={(e) => e.cities_name}
                      onChange={(e) => {
                        setSelectRef({ ...selectRef, city: e.cities_name });
                        setFieldValue("billingCity", e.cities_id);
                        setFieldValue("city_name", e.cities_name);
                      }}
                      key={values.billingCity !== "" ? Math.random() : "123"}
                    />
                    {touched.billingCity && errors.billingCity ? (
                      <div className={Styles.errMsg + " text-danger"}>
                        {errors.billingCity}
                      </div>
                    ) : null}
                  </div>
                </div>

                <div className={Styles.formContainer}>
                  <div className={Styles.inputLeftContainer}>
                    <label className="label">
                      Address
                      <span className="text-danger fs-5">*</span>
                    </label>
                    <div className={Styles.inputContainer}>
                      <input
                        type="text"
                        name="billingAddress"
                        value={values.billingAddress}
                        disabled={isDisabled}
                        onChange={handleChange}
                        placeholder="Enter Your Address"
                        className={Styles.input + " kstoreFont"}
                      />
                    </div>
                    {touched.billingAddress && errors.billingAddress ? (
                      <div className={Styles.errMsg + " text-danger"}>
                        {errors.billingAddress}
                      </div>
                    ) : null}
                  </div>
                  <div className={Styles.inputRightContainer}>
                    <label className="label">
                      e-Mail
                      <span className="text-danger fs-5">*</span>
                    </label>
                    <div className={Styles.inputContainer}>
                      <input
                        type="text"
                        name="billingEmail"
                        value={values.billingEmail}
                        disabled={isDisabled}
                        onChange={handleChange}
                        placeholder="Enter Your e-Mail"
                        className={Styles.input + " kstoreFont6"}
                      />
                    </div>
                    {touched.billingEmail && errors.billingEmail ? (
                      <div className={Styles.errMsg + " text-danger"}>
                        {errors.billingEmail}
                      </div>
                    ) : null}
                  </div>
                </div>

                <div className={Styles.formContainer}>
                  <div className={Styles.inputLeftContainer}>
                    <label className="label">
                      Pincode <span className="text-danger fs-5">*</span>
                    </label>
                    <div className={Styles.inputContainer}>
                      <input
                        type="text"
                        name="billingPincode"
                        value={values.billingPincode}
                        placeholder="Enter Pincode"
                        onChange={handleChange}
                        disabled={isDisabled}
                        maxLength="6"
                        className={Styles.input + " kstoreFont"}
                        onKeyPress={(e) => {
                          if (!(e.key === "0" || parseInt(e.key))) {
                            e.preventDefault();
                          }
                        }}
                      />
                    </div>
                    {touched.billingPincode && errors.billingPincode ? (
                      <div className={Styles.errMsg + " text-danger"}>
                        {errors.billingPincode}
                      </div>
                    ) : null}
                  </div>
                  {state?.status !== 2 ? (
                    <div className={Styles.inputRightContainer}>
                      <label className="label">GST Number</label>
                      <div className={Styles.inputContainer}>
                        <input
                          type="text"
                          name="gst_no"
                          value={values.gst_no}
                          placeholder="Enter GST Number"
                          maxLength={15}
                          onChange={handleChange}
                          className={Styles.input + " kstoreFont"}
                        />
                      </div>
                      {touched.gst_no && errors.gst_no ? (
                        <div className={Styles.errMsg + " text-danger"}>
                          {errors.gst_no}
                        </div>
                      ) : null}
                    </div>
                  ) : null}
                </div>

                {state?.status && state?.status !== 2 ? (
                  <div className={Styles.formContainer}>
                    <div className={Styles.inputLeftContainer}>
                      <label className="label">
                        Invoice Number
                        <span className="text-danger fs-5">*</span>
                      </label>
                      <div className={Styles.inputContainer}>
                        <input
                          type="text"
                          name="invoice_no"
                          value={values.invoice_no}
                          placeholder="Enter Invoice Number"
                          maxLength={50}
                          onChange={handleChange}
                          className={Styles.input + " kstoreFont"}
                        />
                      </div>
                      {touched.invoice_no && errors.invoice_no ? (
                        <div className={Styles.errMsg + " text-danger"}>
                          {errors.invoice_no}
                        </div>
                      ) : null}
                    </div>
                  </div>
                ) : null}

                <p className="fontBold">
                  Shipping <span className="orange">Address</span>
                </p>
                <div className={Styles.checkboxContainer}>
                  <input
                    className={Styles.ShowShippingDetail}
                    type="checkbox"
                    value={isShowShipping}
                    checked={!isShowShipping}
                    disabled={isDisabled}
                    onChange={() => setIsShowShipping(!isShowShipping)}
                  />
                  <label className={Styles.shippingLabel + " kstoreFont ps-2"}>
                    Shipping Address Same as Billing Address
                  </label>
                </div>
                <div style={{ display: isShowShipping ? "block" : "none" }}>
                  <div className={Styles.formContainer}>
                    <div className={Styles.inputLeftContainer}>
                      <label className="label">
                        Name <span className="text-danger fs-5">*</span>
                      </label>
                      <div className={Styles.inputContainer}>
                        <input
                          type="text"
                          onChange={handleChange}
                          name="shippingName"
                          value={values.shippingName}
                          placeholder="Enter Your Name"
                          className={Styles.input + " kstoreFont"}
                        />
                      </div>
                      {touched.shippingName && errors.shippingName ? (
                        <div className={Styles.errMsg + " text-danger"}>
                          {errors.shippingName}
                        </div>
                      ) : null}
                    </div>

                    <div className={Styles.inputRightContainer}>
                      <label className="label">
                        Mobile Number{" "}
                        <span className="text-danger fs-5">*</span>
                      </label>
                      <div className={Styles.inputContainer}>
                        <input
                          type="text"
                          name="shippingMobile"
                          value={values.shippingMobile}
                          onChange={handleChange}
                          placeholder="Enter Your Mobile Number"
                          maxLength="10"
                          className={Styles.input + " kstoreFont"}
                          onKeyPress={(e) => {
                            if (!(e.key === "0" || parseInt(e.key))) {
                              e.preventDefault();
                            }
                          }}
                        />
                      </div>
                      {touched.shippingMobile && errors.shippingMobile ? (
                        <div className={Styles.errMsg + " text-danger"}>
                          {errors.shippingMobile}
                        </div>
                      ) : null}
                    </div>
                  </div>

                  <div className={Styles.formContainer}>
                    <div className={Styles.inputLeftContainer}>
                      <label className="label">
                        State <span className="text-danger fs-5">*</span>
                      </label>
                      <ReactSelect
                        className="basic-single kstoreFont mt-2"
                        classNamePrefix="select"
                        name="shippingState"
                        placeholder="Select State..."
                        isClearable={false}
                        value={states.find(
                          (e) => e.state_id === values.shippingState
                        )}
                        styles={customStyles}
                        options={states}
                        getOptionLabel={(e) => e.state_name}
                        getOptionValue={(e) => e.state_name}
                        onChange={(e) => {
                          handleCity(e.state_id);
                          setFieldValue("shippingState", e.state_id);
                          setFieldValue("shipping_statename", e.state_name);
                          setSelectRef({
                            ...selectRef,
                            state: e.state_name,
                            city: "",
                          });
                        }}
                      />
                      {touched.shippingState && errors.shippingState ? (
                        <div className={Styles.errMsg + " text-danger"}>
                          {errors.shippingState}
                        </div>
                      ) : null}
                    </div>
                    <div className={Styles.inputRightContainer}>
                      <label className="label">
                        City <span className="text-danger fs-5">*</span>
                      </label>
                      <ReactSelect
                        className="basic-single kstoreFont mt-2"
                        classNamePrefix="select"
                        placeholder="Select City..."
                        name="shippingCity"
                        defaultInputValue={selectRef.city}
                        value={city.find(
                          (e) => e.cities_id === values.shippingCity
                        )}
                        isClearable={false}
                        styles={customStyles}
                        options={city}
                        getOptionLabel={(e) => e.cities_name}
                        getOptionValue={(e) => e.cities_name}
                        onChange={(e) => {
                          setSelectRef({ ...selectRef, city: e.cities_name });
                          setFieldValue("shippingCity", e.cities_id);
                          setFieldValue("shipping_cityname", e.cities_name);
                        }}
                        key={values.shippingCity !== "" ? Math.random() : "123"}
                      />
                      {touched.shippingCity && errors.shippingCity ? (
                        <div className={Styles.errMsg + " text-danger"}>
                          {errors.shippingCity}
                        </div>
                      ) : null}
                    </div>
                  </div>

                  <div className={Styles.formContainer}>
                    <div className={Styles.inputLeftContainer}>
                      <label className="label">
                        Address
                        <span className="text-danger fs-5">*</span>
                      </label>
                      <div className={Styles.inputContainer}>
                        <input
                          type="text"
                          name="shippingAddress"
                          value={values.shippingAddress}
                          onChange={handleChange}
                          placeholder="Enter Your Address"
                          className={Styles.input + " kstoreFont"}
                        />
                      </div>
                      {touched.shippingAddress && errors.shippingAddress ? (
                        <div className={Styles.errMsg + " text-danger"}>
                          {errors.shippingAddress}
                        </div>
                      ) : null}
                    </div>
                    <div className={Styles.inputRightContainer}>
                      <label className="label">
                        e-Mail
                        <span className="text-danger fs-5">*</span>
                      </label>
                      <div className={Styles.inputContainer}>
                        <input
                          type="text"
                          name="shippingEmail"
                          onChange={handleChange}
                          value={values.shippingEmail}
                          placeholder="Enter Your e-Mail"
                          className={Styles.input + " kstoreFont6"}
                        />
                      </div>
                      {touched.shippingEmail && errors.shippingEmail ? (
                        <div className={Styles.errMsg + " text-danger"}>
                          {errors.shippingEmail}
                        </div>
                      ) : null}
                    </div>
                  </div>

                  <div className={Styles.formContainer}>
                    <div className={Styles.inputLeftContainer}>
                      <label className="label">
                        Pincode <span className="text-danger fs-5">*</span>
                      </label>
                      <div className={Styles.inputContainer}>
                        <input
                          type="text"
                          name="shippingPincode"
                          placeholder="Enter Pincode"
                          maxLength="6"
                          value={values.shippingPincode}
                          onChange={handleChange}
                          className={Styles.input + " kstoreFont"}
                          onKeyPress={(e) => {
                            if (!(e.key === "0" || parseInt(e.key))) {
                              e.preventDefault();
                            }
                          }}
                        />
                      </div>
                      {touched.shippingPincode && errors.shippingPincode ? (
                        <div className={Styles.errMsg + " text-danger"}>
                          {errors.shippingPincode}
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>

                {state?.status === 1 &&
                !pathname.includes("/kansas_product") ? (
                  <div className={Styles.paymentContainer}>
                    <p className={Styles.paymentTitle + " fontBold"}>
                      Payment <span className="orange">Mode</span>{" "}
                    </p>
                    {/* <div className={Styles.paymentSec}>
                      <input
                        value="1"
                        className={
                          Styles.paymentRadioBtn + " align-self-center"
                        }
                        type="radio"
                        onChange={() => setFieldValue("paymentStatus", 1)}
                        checked={parseInt(values.paymentStatus) === 1}
                      />
                      <label
                        onClick={() => setFieldValue("paymentStatus", 1)}
                        className={Styles.paymentLabel + " label"}
                      >
                        UPI
                      </label>
                    </div> */}

                    {/* <div className={Styles.paymentSec}>
                      <input
                        value="7"
                        className={
                          Styles.paymentRadioBtn + " align-self-center"
                        }
                        type="radio"
                        onChange={() => setFieldValue("paymentStatus", 7)}
                        checked={parseInt(values.paymentStatus) === 7}
                      />
                      <label
                        onClick={() => setFieldValue("paymentStatus", 7)}
                        className={Styles.paymentLabel + " label"}
                      >
                        QR Code
                      </label>
                    </div> */}

                    {/* <div className={Styles.paymentSec}>
                      <input
                        value="2"
                        className={
                          Styles.paymentRadioBtn + " align-self-center"
                        }
                        type="radio"
                        onChange={() => setFieldValue("paymentStatus", 2)}
                        checked={parseInt(values.paymentStatus) === 2}
                      />
                      <label
                        onClick={() => setFieldValue("paymentStatus", 2)}
                        className={Styles.paymentLabel + " label"}
                      >
                        CREDIT / DEBIT / NET BANKING
                      </label>
                    </div> */}
                    <div className={Styles.paymentSec}>
                      <input
                        value="3"
                        className={
                          Styles.paymentRadioBtn + " align-self-center"
                        }
                        type="radio"
                        onChange={() => setFieldValue("paymentStatus", 3)}
                        checked={parseInt(values.paymentStatus) === 3}
                      />
                      <label
                        onClick={() => setFieldValue("paymentStatus", 3)}
                        className={Styles.paymentLabel + " label"}
                      >
                        CASH
                      </label>
                    </div>
                    <div className={Styles.paymentSec}>
                      <input
                        value="3"
                        className={
                          Styles.paymentRadioBtn + " align-self-center"
                        }
                        type="radio"
                        onChange={() => setFieldValue("paymentStatus", 4)}
                        checked={parseInt(values.paymentStatus) === 4}
                      />
                      <label
                        onClick={() => setFieldValue("paymentStatus", 4)}
                        className={Styles.paymentLabel + " label"}
                      >
                        EMI
                      </label>
                      {values.paymentStatus === 4 ? (
                        <select
                          name="emiOption"
                          value={values.emiOption}
                          onChange={(e) => {
                            setFieldValue("emiOption", e.target.value);
                          }}
                          className={Styles.selectPayment}
                        >
                          <option>Select Emi Provider</option>
                          {emiProviders?.map((ele) => (
                            <option
                              onClick={() =>
                                setFieldValue("emiProvidername", ele.name)
                              }
                              value={ele.emi_type_id}
                            >
                              {ele.name}
                            </option>
                          ))}
                        </select>
                      ) : null}
                      {touched.emiOption &&
                      errors.emiOption &&
                      values.paymentStatus === 4 ? (
                        <div
                          className={
                            Styles.errMsg +
                            " text-danger align-self-center ps-3"
                          }
                        >
                          {errors.emiOption}
                        </div>
                      ) : null}
                    </div>
                    <div className={Styles.paymentSec}>
                      <input
                        value="3"
                        className={
                          Styles.paymentRadioBtn + " align-self-center"
                        }
                        type="radio"
                        onChange={() => setFieldValue("paymentStatus", 5)}
                        checked={parseInt(values.paymentStatus) === 5}
                      />
                      <label
                        onClick={() => setFieldValue("paymentStatus", 5)}
                        className={Styles.paymentLabel + " label"}
                      >
                        POS Machine / UPI / CARD
                      </label>
                      {values.paymentStatus === 5 ? (
                        <input
                          type="text"
                          name="posMachine"
                          className={Styles.pos}
                          value={values.posMachine}
                          onChange={(e) =>
                            setFieldValue("posMachine", e.target.value)
                          }
                          placeholder="Enter Transaction id"
                        />
                      ) : null}
                      {touched.posMachine &&
                      errors.posMachine &&
                      values.paymentStatus === 5 ? (
                        <div
                          className={
                            Styles.errMsg +
                            " text-danger align-self-center ps-3"
                          }
                        >
                          {errors.posMachine}
                        </div>
                      ) : null}
                    </div>

                    <div className={Styles.paymentSec}>
                      <input
                        value="8"
                        className={
                          Styles.paymentRadioBtn + " align-self-center"
                        }
                        type="radio"
                        onChange={() => setFieldValue("paymentStatus", 8)}
                        checked={parseInt(values.paymentStatus) === 8}
                      />
                      <label
                        onClick={() => setFieldValue("paymentStatus", 8)}
                        className={Styles.paymentLabel + " label"}
                      >
                        RAZORPAY
                      </label>
                    </div>
                    {values.paymentStatus === 8 ? (
                      <div>
                        <div
                          className={Styles.checkboxContainer}
                          style={{ marginLeft: "10px" }}
                        >
                          <input
                            className={Styles.ShowShippingDetail}
                            type="checkbox"
                            value={isNeedCustomerUrl}
                            checked={isNeedCustomerUrl}
                            disabled={isDisabled}
                            onChange={() =>
                              setIsNeedCustomerUrl(!isNeedCustomerUrl)
                            }
                          />
                          <label
                            className={
                              Styles.shippingLabel + " kstoreFont ps-2"
                            }
                          >
                            Sent Payment link SMS to Customer
                          </label>
                        </div>
                        {isNeedCustomerUrl ? (
                          <div
                            style={{ flexDirection: "row", display: "flex" }}
                          >
                            <div style={{ width: "48%" }}>
                              <input
                                type="text"
                                name="customer_name"
                                className={Styles.pos}
                                value={values.customer_name}
                                onChange={(e) =>
                                  setFieldValue("customer_name", e.target.value)
                                }
                                placeholder="Enter Customer Name"
                              />
                              {touched.customer_name && errors.customer_name ? (
                                <div
                                  className={
                                    Styles.errMsg +
                                    " text-danger align-self-center ps-3"
                                  }
                                >
                                  {errors.customer_name}
                                </div>
                              ) : null}
                            </div>
                            <div style={{ width: "48%" }}>
                              <input
                                type="text"
                                name="customer_mobile"
                                className={Styles.pos}
                                value={values.customer_mobile}
                                onChange={(e) =>
                                  setFieldValue(
                                    "customer_mobile",
                                    e.target.value
                                  )
                                }
                                placeholder="Enter Mobile Number"
                                onKeyPress={(e) => {
                                  if (!(e.key === "0" || parseInt(e.key))) {
                                    e.preventDefault();
                                  }
                                }}
                                maxLength={10}
                              />

                              {touched.customer_mobile &&
                              errors.customer_mobile ? (
                                <div
                                  className={
                                    Styles.errMsg +
                                    " text-danger align-self-center ps-3"
                                  }
                                >
                                  {errors.customer_mobile}
                                </div>
                              ) : null}
                            </div>
                          </div>
                        ) : null}
                      </div>
                    ) : null}

                    {/* <div className={Styles.paymentSec}>
                      <input
                        value="3"
                        className={
                          Styles.paymentRadioBtn + " align-self-center"
                        }
                        type="radio"
                        onChange={() => {
                          setFieldValue("paymentStatus", 6);
                        }}
                        checked={parseInt(values.paymentStatus) === 6}
                      />
                      <label
                        onClick={() => {
                          setFieldValue("paymentStatus", 6);
                        }}
                        className={Styles.paymentLabel + " label"}
                      >
                        Send Payment Link
                      </label>
                      {values.paymentStatus === 6 ? (
                        <input
                          name="paymentLinkNo"
                          onKeyPress={(e) => {
                            if (e.key === "0" || parseInt(e.key)) {
                            } else {
                              e.preventDefault();
                            }
                          }}
                          onChange={(e) => {
                            setFieldValue("paymentLinkNo", e.target.value);
                          }}
                          type="text"
                          maxLength={10}
                          value={values.paymentLinkNo}
                          placeholder="Enter Mobile Number"
                          className={Styles.pos}
                        />
                      ) : null}
                    </div> */}
                    {touched.paymentLinkNo &&
                    errors.paymentLinkNo &&
                    values.paymentStatus === 6 ? (
                      <div
                        className={
                          Styles.errMsg + " text-danger align-self-center ps-3"
                        }
                      >
                        {errors.paymentLinkNo}
                      </div>
                    ) : null}
                    {touched.paymentStatus && errors.paymentStatus ? (
                      <div className={Styles.errMsg + " text-danger"}>
                        {errors.paymentStatus}
                      </div>
                    ) : null}
                  </div>
                ) : null}

                <div className={Styles.btnContainer + " text-end"}>
                  <button
                    onClick={() => navigate(-1)}
                    className={Styles.cancelBtn}
                  >
                    <img className={Styles.nextIcon} src={next} alt="next" />
                    Previous
                  </button>
                  <button
                    type="submit"
                    onClick={handleSubmit}
                    className={Styles.saveBtn}
                  >
                    Next
                    <img
                      className={Styles.nextWhite}
                      src={nextWhite}
                      alt="next"
                    />
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>

        <Modal
          centered
          size="md"
          show={showModal}
          onHide={() => setShowModal(false)}
        >
          <Modal.Body>
            <div
              onClick={() => setShowModal(false)}
              className={Styles.cancelParent}
            >
              <div className={Styles.cancelContainer}>
                <img className={Styles.cancelImg} src={cancel} alt="cancel" />
              </div>
            </div>

            <div>
              <p className="label">Payment Link</p>
              <div className={Styles.PaymentInputContainer}>
                <label className="label">Mobile No</label>
                <input
                  name="paymentLinkNo"
                  onKeyPress={(e) => {
                    if (e.key === "0" || parseInt(e.key)) {
                    } else {
                      e.preventDefault();
                    }
                  }}
                  onChange={handleChange}
                  type="text"
                  maxLength={10}
                  value={values.paymentLinkNo}
                  placeholder="Enter Mobile Number"
                  className={Styles.paymentLink}
                />
              </div>
              {touched.paymentLinkNo && errors.paymentLinkNo ? (
                <div className={Styles.errMsg + " text-danger"}>
                  {errors.paymentLinkNo}
                </div>
              ) : null}
              <div className="text-end mt-2 mb-2">
                <button
                  onClick={() => {
                    if (values.paymentLinkNo === "" || errors.paymentLinkNo) {
                      console.log(
                        touched.paymentLinkNo,
                        "ASADASDSA",
                        errors.paymentLinkNo
                      );
                      setPaymentLinkError(true);
                      validateForm();
                    } else {
                      setPaymentLinkError(false);
                      setShowModal(false);
                    }
                  }}
                  className={Styles.paymentLinkBtn}
                >
                  Submit
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </div>
  );
}
