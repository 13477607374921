import { Modal } from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useCallback, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import lodash from "lodash";
import Styles from "./PaymentView.module.css";
import {
  emiProviderService,
  failedBillService,
} from "../../../Services/ApiServices";
import cancel from "../../../Assets/cross.png";
import paymentSuccess from "../../../Assets/paymentSuccess.png";
import { phoneExp } from "../../../Utility/Utility";

export default function PaymentView(props) {
  const [showModal, setShowModal] = useState(false);
  const [emiProviders, setEmiProviders] = useState([]);
  const [paymentLinkError, setPaymentLinkError] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [isNeedCustomerUrl, setIsNeedCustomerUrl] = useState(false);
  const [showMsgSentStatus, setShowMsgSentStatus] = useState({
    status: false,
    data: null,
  });
  let navigate = useNavigate();

  useEffect(() => {
    handleEmiProviders();
  }, []);
  const {
    handleSubmit,
    values,
    errors,
    touched,
    handleChange,
    setFieldValue,
    isSubmitting,
  } = useFormik({
    initialValues: {
      payment_type: "",
      emiOption: "",
      posMachine: "",
      paymentLinkNo: "",
      customer_name: "",
      customer_mobile: "",
    },
    validationSchema: Yup.object({
      payment_type: Yup.string().required("Required"),
      paymentLinkNo: Yup.string().matches(
        phoneExp,
        "Enter a valid Mobile number"
      ),
      customer_mobile: Yup.string().matches(
        phoneExp,
        "Mobile number must start with 9 or 8 or 7 or 6 "
      ),
    }),
    validate: () => {
      let err = {};
      if (values.payment_type === 4 && values.emiOption === "") {
        err.emiOption = "Required";
      }
      if (values.payment_type === 5 && values.posMachine === "") {
        err.posMachine = "Required";
      }
      if (values.payment_type === 6 && values.paymentLinkNo === "") {
        err.paymentLinkNo = "Required";
      }
      if (
        values.payment_type === 8 &&
        isNeedCustomerUrl &&
        values.customer_name === ""
      ) {
        err.customer_name = "Required";
      }
      if (
        values.payment_type === 8 &&
        isNeedCustomerUrl &&
        values.customer_mobile === ""
      ) {
        err.customer_mobile = "Required";
      }
      return err;
    },
    onSubmit: (values) => {
      setIsDisabled(true);
      debounceOnSubmit({ ...values, send_sms: isNeedCustomerUrl ? 1 : 0 });
      // handlePayment(values);
    },
  });

  const handleEmiProviders = () => {
    emiProviderService()
      .then((res) => {
        setEmiProviders(res.data);
      })
      .catch((err) => {
        if (err?.response?.data?.detail) {
          toast(err.response.data.detail[0].msg, { type: "error" });
        } else {
          toast("Something went wrong!!");
        }
      });
  };

  const handlePayment = (data) => {
    let formData = new FormData();
    formData.append("payment_type", parseInt(data.payment_type));
    if (data.payment_type === 4) {
      formData.append("emi", parseInt(data.emiOption));
    }
    if (data.payment_type === 5) {
      formData.append("pos_machine", data.posMachine);
    }
    if (data.payment_type === 6) {
      formData.append("mobile_send_payment", data.paymentLinkNo);
    }
    if (data.payment_type === 8) {
      formData.append("payment_customer_name", data.customer_name);
      formData.append("payment_mobile_no", data.customer_mobile);
      formData.append("send_sms", data.send_sms);
    }
    formData.append("bill_id", parseInt(props.id));
    failedBillService(formData)
      .then((res) => {
        setIsDisabled(false);
        if (
          (data.payment_type === 1 ||
            data.payment_type === 2 ||
            data.payment_type === 8) &&
          res.data.status === 1
        ) {
          window.open(res.data.url, "_self");
          props.onHide();
          props.handleListBill(props?.tableData?.page);
        } else if (
          data.payment_type === 3 ||
          data.payment_type === 4 ||
          data.payment_type === 5 ||
          data.payment_type === 6 ||
          data.payment_type === 7
        ) {
          toast("Success", { type: "success" });
          navigate("/store/bill_list");
          props.onHide();
          props.handleListBill(props?.tableData?.page);
        } else if (data.payment_type === 8 && res.data.status === 2) {
          setShowMsgSentStatus({
            data: res.data.msg,
            status: true,
          });
        }
      })
      .catch((err) => {
        setIsDisabled(false);
        if (err?.response?.data?.detail) {
          toast.error(err.response.data.detail[0].msg);
        } else {
          toast.error("Something went wrong !!");
        }
      });
  };

  const debounceOnSubmit = useCallback(lodash.debounce(handlePayment, 300), []);

  return (
    <div>
      {showMsgSentStatus.status ? (
        <Modal
          centered
          size="md"
          show={showMsgSentStatus.status}
          onHide={() => {
            setShowMsgSentStatus({
              data: null,
              status: false,
            });
            props.onHide();
            props.handleListBill(props?.tableData?.page);
          }}
        >
          <Modal.Body>
            <div>
              <div className="text-center">
                <img src={paymentSuccess} alt="success" />
              </div>
              <p className={Styles.paymentMsg}>{showMsgSentStatus.data}</p>
            </div>
          </Modal.Body>
        </Modal>
      ) : null}
      <Modal centered size="md" show={props.onShow} onHide={props.onHide}>
        <Modal.Body>
          <div onClick={props.onHide} className={Styles.cancelParent}>
            <div className={Styles.cancelContainer}>
              <img className={Styles.cancelImg} src={cancel} alt="cancel" />
            </div>
          </div>

          <div>
            <p className="label">
              Payment <span className="orange">Type</span>{" "}
            </p>

            <form onSubmit={(e) => e.preventDefault()}>
              <div className={Styles.parentFormContainer}>
                {/* <div className={Styles.childFormContainer}>
                  <input
                    className={Styles.inputRadio}
                    type="radio"
                    value="1"
                    name="payment_type"
                    checked={parseInt(values.payment_type) === 1}
                    onChange={() => setFieldValue("payment_type", 1)}
                  />
                  <label
                    onClick={() => setFieldValue("payment_type", 1)}
                    className={Styles.label}
                  >
                    UPI
                  </label>
                </div> */}
                {/* <div className={Styles.childFormContainer}>
                  <input
                    className={Styles.inputRadio}
                    type="radio"
                    value="7"
                    name="payment_type"
                    checked={parseInt(values.payment_type) === 7}
                    onChange={() => setFieldValue("payment_type", 7)}
                  />
                  <label
                    onClick={() => setFieldValue("payment_type", 7)}
                    className={Styles.label}
                  >
                    QR Code
                  </label>
                </div> */}
                {/* <div className={Styles.childFormContainer}>
                  <input
                    className={Styles.inputRadio}
                    type="radio"
                    value="2"
                    name="payment_type"
                    checked={parseInt(values.payment_type) === 2}
                    onChange={() => setFieldValue("payment_type", 2)}
                  />
                  <label
                    onClick={() => setFieldValue("payment_type", 2)}
                    className={Styles.label}
                  >
                    CREDIT / DEBIT / NET BANKING
                  </label>
                </div> */}
                <div className={Styles.childFormContainer}>
                  <input
                    className={Styles.inputRadio}
                    type="radio"
                    value="3"
                    name="payment_type"
                    checked={parseInt(values.payment_type) === 3}
                    onChange={() => setFieldValue("payment_type", 3)}
                  />
                  <label
                    onClick={() => setFieldValue("payment_type", 3)}
                    className={Styles.label}
                  >
                    CASH
                  </label>
                </div>
                <div className={Styles.paymentSec}>
                  <input
                    value="3"
                    className={Styles.paymentRadioBtn + " align-self-center"}
                    type="radio"
                    onChange={() => setFieldValue("payment_type", 4)}
                    checked={parseInt(values.payment_type) === 4}
                  />
                  <label
                    onClick={() => setFieldValue("payment_type", 4)}
                    className={Styles.label + " label"}
                  >
                    EMI
                  </label>
                  {values.payment_type === 4 ? (
                    <select
                      name="emiOption"
                      value={values.emiOption}
                      onChange={(e) => {
                        setFieldValue("emiOption", e.target.value);
                      }}
                      className={Styles.selectPayment}
                    >
                      <option>Select Emi Provider</option>
                      {emiProviders?.map((ele) => (
                        <option
                          onClick={() =>
                            setFieldValue("emiProvidername", ele.name)
                          }
                          value={ele.emi_type_id}
                        >
                          {ele.name}
                        </option>
                      ))}
                    </select>
                  ) : null}
                  {touched.emiOption &&
                  errors.emiOption &&
                  values.payment_type === 4 ? (
                    <div
                      className={
                        Styles.errMsg + " text-danger align-self-center ps-3"
                      }
                    >
                      {errors.emiOption}
                    </div>
                  ) : null}
                </div>
                <div className={Styles.paymentSec}>
                  <input
                    value="3"
                    className={Styles.paymentRadioBtn + " align-self-center"}
                    type="radio"
                    onChange={() => setFieldValue("payment_type", 5)}
                    checked={parseInt(values.payment_type) === 5}
                  />
                  <label
                    onClick={() => setFieldValue("payment_type", 5)}
                    className={Styles.label + " label"}
                  >
                    POS Machine / UPI / CARD
                  </label>
                  {values.payment_type === 5 ? (
                    <input
                      type="text"
                      name="posMachine"
                      className={Styles.pos}
                      value={values.posMachine}
                      onChange={(e) =>
                        setFieldValue("posMachine", e.target.value)
                      }
                      placeholder="Enter Transaction id"
                    />
                  ) : null}

                  {touched.posMachine &&
                  errors.posMachine &&
                  values.payment_type === 5 ? (
                    <div
                      className={
                        Styles.errMsg + " text-danger align-self-center ps-3"
                      }
                    >
                      {errors.posMachine}
                    </div>
                  ) : null}
                </div>
                {/* <div className={Styles.paymentSec}>
                  <input
                    value="3"
                    className={Styles.paymentRadioBtn + " align-self-center"}
                    type="radio"
                    onChange={() => {
                      setFieldValue("payment_type", 6);
                      // setShowModal(true);
                    }}
                    checked={parseInt(values.payment_type) === 6}
                  />
                  <label
                    onClick={() => {
                      setFieldValue("payment_type", 6);
                      // setShowModal(true);
                    }}
                    className={Styles.label + " label"}
                  >
                    Send Payment Link
                  </label>

                  {parseInt(values.payment_type) === 6 ? (
                    <>
                      <input
                        name="paymentLinkNo"
                        onKeyPress={(e) => {
                          if (e.key === "0" || parseInt(e.key)) {
                          } else {
                            e.preventDefault();
                          }
                        }}
                        onChange={handleChange}
                        type="text"
                        maxLength={10}
                        value={values.paymentLinkNo}
                        placeholder="Enter Mobile Number"
                        className={Styles.pos}
                      />
                      {paymentLinkError ? (
                        <p className={Styles.errMsg + " text-danger"}>
                          Required
                        </p>
                      ) : null}
                    </>
                  ) : null}
                </div>
                {touched.paymentLinkNo && errors.paymentLinkNo ? (
                  <div
                    className={
                      Styles.errMsg + " text-danger align-self-center ps-3"
                    }
                  >
                    {errors.paymentLinkNo}
                  </div>
                ) : null} */}

                <div className={Styles.paymentSec}>
                  <input
                    value="8"
                    className={Styles.paymentRadioBtn + " align-self-center"}
                    type="radio"
                    onChange={() => setFieldValue("payment_type", 8)}
                    checked={parseInt(values.payment_type) === 8}
                  />
                  <label
                    onClick={() => setFieldValue("payment_type", 8)}
                    className={Styles.label + " label"}
                  >
                    RAZORPAY
                  </label>
                </div>
                {values.payment_type === 8 ? (
                  <div>
                    <div
                      className={Styles.checkboxContainer}
                      style={{ marginLeft: "10px" }}
                    >
                      <input
                        className={Styles.ShowShippingDetail}
                        type="checkbox"
                        value={isNeedCustomerUrl}
                        checked={isNeedCustomerUrl}
                        disabled={isDisabled}
                        onChange={() =>
                          setIsNeedCustomerUrl(!isNeedCustomerUrl)
                        }
                      />
                      <label className={Styles.label + " label "}>
                        Sent Payment link SMS to Customer
                      </label>
                    </div>
                    {isNeedCustomerUrl ? (
                      <div style={{ flexDirection: "row", display: "flex" }}>
                        <div style={{ width: "48%" }}>
                          <input
                            type="text"
                            name="customer_name"
                            className={Styles.pos}
                            value={values.customer_name}
                            onChange={(e) =>
                              setFieldValue("customer_name", e.target.value)
                            }
                            placeholder="Enter Customer Name"
                          />
                          {touched.customer_name && errors.customer_name ? (
                            <div
                              className={
                                Styles.errMsg +
                                " text-danger align-self-center ps-3"
                              }
                            >
                              {errors.customer_name}
                            </div>
                          ) : null}
                        </div>
                        <div style={{ width: "48%" }}>
                          <input
                            type="text"
                            name="customer_mobile"
                            className={Styles.pos}
                            value={values.customer_mobile}
                            onChange={(e) =>
                              setFieldValue("customer_mobile", e.target.value)
                            }
                            placeholder="Enter Mobile Number"
                            onKeyPress={(e) => {
                              if (!(e.key === "0" || parseInt(e.key))) {
                                e.preventDefault();
                              }
                            }}
                            maxLength={10}
                          />

                          {touched.customer_mobile && errors.customer_mobile ? (
                            <div
                              className={
                                Styles.errMsg +
                                " text-danger align-self-center ps-3"
                              }
                            >
                              {errors.customer_mobile}
                            </div>
                          ) : null}
                        </div>
                      </div>
                    ) : null}
                  </div>
                ) : null}
              </div>
              <div className="text-end">
                <button onClick={handleSubmit} className={Styles.btn}>
                  Submit
                </button>
              </div>
            </form>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        centered
        size="lg"
        show={showModal}
        onHide={() => setShowModal(false)}
      >
        <Modal.Body>
          <div
            onClick={() => setShowModal(false)}
            className={Styles.cancelParent}
          >
            <div className={Styles.cancelContainer}>
              <img className={Styles.cancelImg} src={cancel} alt="cancel" />
            </div>
          </div>

          <div>
            <p className="label">Payment Link</p>
            <div className={Styles.PaymentInputContainer}>
              <label className="label">Mobile No</label>
              <input
                name="paymentLinkNo"
                onKeyPress={(e) => {
                  if (e.key === "0" || parseInt(e.key)) {
                  } else {
                    e.preventDefault();
                  }
                }}
                onChange={handleChange}
                type="text"
                maxLength={10}
                value={values.paymentLinkNo}
                placeholder="Enter Mobile Number"
                className={Styles.paymentLink}
              />
            </div>
            {paymentLinkError ? (
              <p className={Styles.errMsg + " text-danger"}>Required</p>
            ) : null}
            <div className="text-end mt-2 mb-2">
              <button
                onClick={() => {
                  console.log(isDisabled, "isDisabled");
                  if (isDisabled) {
                    if (values.paymentLinkNo === "") {
                      setPaymentLinkError(true);
                    } else {
                      setPaymentLinkError(false);
                      setShowModal(false);
                    }
                  }
                }}
                disabled={isDisabled}
                className={Styles.paymentLinkBtn}
              >
                Submit
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}
