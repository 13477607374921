import React, { createRef, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Link, useNavigate } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import toast from "react-hot-toast";
import Styles from "./SignIn.module.css";
import visibleImg from "../../Assets/visible.png";
import unvisible from "../../Assets/unvisible.png";
import { loginService } from "../../Services/ApiServices";
import { setCookie } from "../../Utility/Utility";
import { useAuth } from "../../Services/Context";
import gif from "../../Assets/kstore.gif";

// const sitekey = "6LfWHqEeAAAAADBVVLGLcZ-qUNiSgmIIZI4zYr4R";
const sitekey = "6LdVfbQUAAAAAPyAQBi_ZMs0dyj97jJhJ_GpuP6R";

function SignIn() {
  let navigate = useNavigate();
  const recaptchaRef = createRef();
  const { handleAuthLogin } = useAuth();
  const [visible, setVisible] = useState(false);
  const [isLoader, setIsLoader] = useState(false);
  const [error, setError] = useState("");

  const { handleChange, handleSubmit, values, errors, touched, setFieldValue } =
    useFormik({
      initialValues: {
        email: "",
        password: "",
        recaptcha: "",
      },
      validationSchema: Yup.object({
        email: Yup.string().required("Required"),
        password: Yup.string().required("Required"),
        recaptcha: Yup.string().required("Captcha is required").nullable(),
      }),
      onSubmit: (values) => {
        handleLogin(values);
        setIsLoader(true);
      },
    });

  const handleLogin = (data) => {
    let formData = new FormData();
    formData.append("username", data.email);
    formData.append("password", data.password);
    loginService(formData)
      .then((res) => {
        handleAuthLogin(res.data);
        setCookie("userlog", JSON.stringify(res.data), 30);
        navigate("/store");
      })
      .catch((err) => {
        if (err?.response?.data?.detail) {
          toast(err.response.data.detail[0].msg, { type: "error" });
          setError(err?.response?.data?.detail[0]?.attempts);
        } else {
          toast("Something went wrong!!", { type: "error" });
        }
      })
      .finally(() => setIsLoader(false));
  };

  function onChange(value) {
    setFieldValue("recaptcha", value);
  }

  return (
    <>
      <img className={Styles.logo} src={gif} alt="kstore" />
      <div className={Styles.overallParent}>
        {isLoader ? (
          <div className={Styles.loaderParent}>
            <div className={Styles.loader}></div>
          </div>
        ) : null}
        <div className={Styles.loginRightContainer}>
          <div>
            <h3 className="kstoreFont">Login</h3>
            <form onSubmit={handleSubmit}>
              <div className="mt-4">
                <label className="label">Username</label>
                <div className={Styles.inputContainer}>
                  <input
                    className={Styles.emailInput + " contentFont"}
                    type="text"
                    name="email"
                    autoComplete="new-password"
                    onChange={handleChange}
                    value={values.email}
                    placeholder="Enter Username"
                  />
                </div>
              </div>
              <p className={Styles.error}>{error}</p>
              {touched.email && errors.email ? (
                <div className={Styles.errorMsg + " text-danger"}>
                  {errors.email}
                </div>
              ) : null}
              <div className="mt-4">
                <label className="label">Password</label>
                <div className={Styles.inputContainer}>
                  <input
                    name="password"
                    onChange={handleChange}
                    autoComplete="new-password"
                    value={values.password}
                    className={Styles.passwordInput + " contentFont"}
                    placeholder="Enter Password"
                    type={visible ? "text" : "password"}
                  />

                  <img
                    onClick={() => setVisible(!visible)}
                    className={Styles.visibleImg}
                    src={visible ? visibleImg : unvisible}
                  />
                </div>
              </div>
              {touched.password && errors.password ? (
                <div className={Styles.errorMsg + " text-danger"}>
                  {errors.password}
                </div>
              ) : null}

              <div className="mt-4">
                <form
                  onSubmit={() => {
                    recaptchaRef.current.execute();
                  }}
                  className="gRecaptcha d-flex justify-content-center"
                >
                  <ReCAPTCHA
                    onErrored
                    ref={recaptchaRef}
                    sitekey={sitekey}
                    onChange={onChange}
                  />
                </form>
                {errors?.recaptcha ? (
                  <div className={Styles.errorMsg + " text-danger ms-5"}>
                    {errors.recaptcha}
                  </div>
                ) : null}
              </div>

              <button
                type="submit"
                className={Styles.loginBtn + " mt-4 kstoreFont"}
              >
                LOGIN
              </button>
            </form>
          </div>
          <div className={Styles.forgotpasswordContainer}>
            <Link
              className={Styles.forgotPassword + " kstoreFont"}
              to="/auth/forgotpassword"
            >
              Forgot Password
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}

export default SignIn;
