import React, { useEffect, useState } from "react";
import Loader from "../../../SharedComponents/Loader/Loader";
import { Modal } from "react-bootstrap";
import Styles from "./OrderStatusmodal.module.css";
import cancelIcon from "../../../Assets/cross.png";
import {
  approvalStatusServices,
  getCourierListServices,
  orderStatusChangeServices,
} from "../../../Services/ApiServices";
import { useFormik } from "formik";
import * as Yup from "yup";
import toast from "react-hot-toast";
export default function OrderStatusChangeModal({
  isOpen,
  onHide,
  orderStatusID,
  listapicall,
}) {
  const [isLoader, setIsLoader] = useState(false);
  const [courierlist, setCourierList] = useState([]);

  const {
    values,
    errors,
    touched,
    handleSubmit,
    handleChange,
    resetForm,
    setValues,
    setFieldValue,
  } = useFormik({
    initialValues: {
      status: 0,
      reason: "",
      invoiceNumber: "",
      courierId: null,
      courireName: "",
      trackingNumber: "",
    },
    validationSchema: Yup.object({
      status: Yup.string().required("Required"),
      courierId: Yup.string()
        .nullable()
        .when("status", {
          is: (val) => val === "2",
          then: Yup.string().nullable().required("Required"),
          otherwise: Yup.string().nullable(), // Tracking number is optional for other statuses
        }),
      trackingNumber: Yup.string().when("status", {
        is: (val) => val === "2",
        then: Yup.string().required("Required"),
        otherwise: Yup.string(), // Tracking number is optional for other statuses
      }),
    }),
    onSubmit: () => {
      handleOrderStatus();
      // handleApprovalStatus();
    },
  });
  const handleCourierList = () => {
    setIsLoader(true);
    getCourierListServices()
      .then((res) => {
        setCourierList(res.data.data);
      })
      .catch((err) => {
        if (err?.response?.data?.detail) {
          toast.error(err.response.data.detail[0].msg, { type: "error" });
        } else {
          toast.error("Something went wrong !!", { type: "error" });
        }
      })
      .finally(() => {
        setIsLoader(false);
      });
  };

  const handleOrderStatus = () => {
    setIsLoader(true);
    let formData = new FormData();
    formData.append("purchase_id", orderStatusID.purchase_id);
    formData.append("order_status", values.status);
    if (values.invoiceNumber) {
      formData.append("invoice_no", values.invoiceNumber);
    }
    if (values.courierId) {
      formData.append("courier_id", values.courierId);
      formData.append("courier_name", values.courireName);
    }

    if (values.trackingNumber) {
      formData.append("tracking_number", values.trackingNumber);
    }
    orderStatusChangeServices(formData)
      .then((res) => {
        listapicall();
        toast.success(res.data.msg, { type: "success" });
        onHide();
        resetForm();
      })
      .catch((err) => {
        if (err?.response?.data?.detail) {
          toast.error(err.response.data.detail[0].msg, { type: "error" });
        } else {
          toast.error("Something went wrong !!", { type: "error" });
        }
      })
      .finally(() => {
        setIsLoader(false);
      });
  };
  useEffect(() => {
    handleCourierList();
  }, []);
  useEffect(() => {
    setValues({
      ...values,
      courierId: orderStatusID?.courier,
      courireName: orderStatusID?.courier_name,
      trackingNumber: orderStatusID?.tracking_id
        ? orderStatusID?.tracking_id
        : "",
    });

    setFieldValue(
      "status",
      orderStatusID?.order_status === 1
        ? 1
        : orderStatusID?.order_status === 2
        ? 2
        : 0
    );
  }, [orderStatusID]);

  const handleClose = () => {
    onHide();
    resetForm();
  };

  return (
    <div>
      <Loader isLoader={isLoader} />
      <Modal
        size="md"
        show={isOpen}
        onHide={onHide}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <div className={Styles.cancelImgParentContainer}>
            <div>
              {orderStatusID?.order_status === 2 ? (
                <p className="fontBold">
                  Update Shipment <span className="orange">Details</span>
                </p>
              ) : (
                <p className="fontBold">
                  Change Order <span className="orange">Status</span>
                </p>
              )}
            </div>
            <div className={Styles.cancelImgContainer}>
              <img
                onClick={handleClose}
                src={cancelIcon}
                alt="cancel"
                className={Styles.cancelImg}
              />
            </div>
          </div>
          <div className="col-md-12 col-sm-12">
            <label className="label">Order Status</label>
            <select
              style={{
                cursor:
                  orderStatusID?.order_status === 2 ? "not-allowed" : "default",
              }}
              disabled={orderStatusID?.order_status === 2 ? true : false}
              className={Styles.select + " contentFont"}
              value={values.status}
              name="status"
              onChange={(e) =>
                handleChange({
                  target: {
                    name: e.target.name,
                    value: Number(e.target.value),
                  },
                })
              }
              // onChange={handleChange}
            >
              {orderStatusID?.order_status === 0 ? (
                <>
                  <option value="">Please Select</option>
                  <option value={0}>Waiting</option>
                  <option value={1}>OrderIn Process</option>
                </>
              ) : orderStatusID?.order_status === 1 ? (
                <>
                  <option value={1}>OrderIn Process</option>
                  <option value={2}>Intransit</option>
                </>
              ) : (
                <option value={2}>Intransit</option>
              )}
            </select>
            {touched.status && errors.status ? (
              <div className={Styles.errorMsg + " text-danger"}>
                {errors.status}
              </div>
            ) : null}
          </div>
          {orderStatusID?.order_status == 0 && values.status === 1 ? (
            <div className="col-md-12 col-sm-12">
              <label className="label">Invoice Number</label>
              <input
                className={Styles.select + " contentFont"}
                placeholder="Invoice Number"
                value={values.invoiceNumber}
                name="invoiceNumber"
                onChange={handleChange}
              />
            </div>
          ) : null}
          {values.status === 2 || orderStatusID?.order_status === 2 ? (
            <>
              <div
                className="col-md-12 col-sm-12"
                style={{ maxHeight: "80px !important" }}
              >
                <label className="label">Courier List</label>
                <select
                  className={Styles.select + " contentFont"}
                  value={
                    values.courierId
                      ? JSON.stringify({
                          Id: values.courierId,
                          name: values.courireName,
                        })
                      : ""
                  }
                  name="courireName"
                  onChange={(e) => {
                    let data = JSON.parse(e.target.value);
                    setFieldValue("courierId", data.Id);
                    setFieldValue("courireName", data.name);
                  }}
                >
                  <option value={""}>Please Select</option>
                  {courierlist.map((ele) => {
                    return (
                      <option
                        selected={ele.id === values.courierId}
                        key={ele.id}
                        value={JSON.stringify({ Id: ele.id, name: ele.name })}
                      >
                        {ele.name}
                      </option>
                    );
                  })}

                  {/* <option value="1">Order In Process</option>
              <option value="2">Intransit</option> */}
                </select>
                {touched.courierId && errors.courierId ? (
                  <div className={Styles.errorMsg + " text-danger"}>
                    {errors.courierId}
                  </div>
                ) : null}
              </div>
              <div className="col-md-12 col-sm-12">
                <label className="label">Tracking Number</label>
                <input
                  className={Styles.select + " contentFont"}
                  placeholder="Tracking Number"
                  value={values.trackingNumber}
                  name="trackingNumber"
                  onChange={handleChange}
                />
                {touched.trackingNumber && errors.trackingNumber ? (
                  <div className={Styles.errorMsg + " text-danger"}>
                    {errors.trackingNumber}
                  </div>
                ) : null}
              </div>
            </>
          ) : null}

          <div className={Styles.btnContainer}>
            {/* <button onClick={resetForm} className={Styles.Resetbtn}>
              Reset
            </button> */}
            <button onClick={handleSubmit} className={Styles.btn}>
              Submit
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}
